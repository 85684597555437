import Example from "https://framer.com/m/framer/Example.js@^1.0."
import { useState, useLayoutEffect, useRef } from "react"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function Card(props) {
    const [windowWidth, setWindowWidth] = useState(
        window ? window.innerWidth : 0
    )

    const timerRef = useRef()

    useLayoutEffect(() => {
        const updateSize = () => {
            window.clearTimeout(timerRef.current)
            timerRef.current = window.setTimeout(
                () => setWindowWidth(window.innerWidth),
                500
            )
        }

        window.addEventListener("resize", updateSize)

        return () => {
            window.removeEventListener("resize", updateSize)
            window.clearTimeout(timerRef.current)
        }
    }, [])

    const style = {
        height: "100px",
        width: Math.max(
            (windowWidth - Math.min(windowWidth, 1150)) / 2 - 30,
            0
        ),
    }

    return <div style={style} />
}
